import { initChatLiveAgentElements } from '@components/chat-live-agent/chat-live-agent'
import { getIsMobileSmall } from '@site/js/utils/breakpoint'
import debounce from '@site/js/utils/debounce'
import { pxToRem } from '@site/js/utils/px-to-rem'

document.querySelectorAll('.cmp-button.cmp-button--sticky').forEach(stickyButton => {
  stickyButton.parentElement.classList.add('button--sticky')
})

const main = document.querySelector('main')
const stickyButton = document.querySelector('.cmp-button--sticky')
const isChatButton = stickyButton?.classList.contains('cmp-button--chat')
const isLinkButton = stickyButton?.classList.contains('cmp-button--link')
const isImgButton = stickyButton?.classList.contains('cmp-button--imgChat')
const fixedButtonClassName = 'cmp-button--fixed'
const maxContentWidth = 1920

const setStickyLinkRightPosition = linkButton => {
  const mainWidth = main.getBoundingClientRect()
  const margin = mainWidth.width < maxContentWidth ? mainWidth.x : 0
  linkButton.style.right = pxToRem(mainWidth.left - margin)
}

const setStickyLinkTopPosition = linkButton => {
  const header = document.querySelector('.cmp-header')
  const headerNavigation = document.querySelector('.cmp-header__navigation')
  const isStickyHeader = header?.classList.contains('cmp-header--sticky')
  const headerHeight = isStickyHeader ? headerNavigation.offsetHeight : header.offsetHeight + header.getBoundingClientRect().top
  const anchorNavigation = document.querySelector('.cmp-anchor-navigation')
  const isStickyAnchorNavigation = anchorNavigation?.classList.contains('cmp-anchor-navigation--sticky')
  const anchorNavigationHeight = isStickyAnchorNavigation && isStickyHeader ? anchorNavigation.offsetHeight : 0

  linkButton.style.top = `${pxToRem(headerHeight + anchorNavigationHeight)}`
}

const setStickyLinkPosition = linkButton => {
  setStickyLinkRightPosition(linkButton)
  setStickyLinkTopPosition(linkButton)
}

const setFixedButtonClass = button => {
  if (button && !button.classList.contains(fixedButtonClassName)) {
    button.classList.add(fixedButtonClassName)
  }
}

setFixedButtonClass(stickyButton)

// window.innerWidth returns a wrong value on mobile.
// There may still be some DOM layering happening before 'DOMContentLoaded'.
// Let's delay it so we give time before getting innerWidth again.
setTimeout(() => {
  if ((isLinkButton || isImgButton) && !getIsMobileSmall()) {
    setStickyLinkPosition(stickyButton)
  }
}, 0)

initChatLiveAgentElements()

const setStickyButtonFixedToTheFooter = entry => {
  stickyButton.style.top = entry.isIntersecting
    ? pxToRem(entry.target.getBoundingClientRect().top - stickyButton.getBoundingClientRect().height - 15)
    : null
  stickyButton.style.marginTop = entry.isIntersecting ? '0' : null
  stickyButton.style.bottom = entry.isIntersecting ? 'auto' : null
  stickyButton.style.zIndex = entry.isIntersecting ? '4' : null
}

const handleScrollAndResize = entry => {
  if (!stickyButton) {
    return
  }

  if (isChatButton) {
    setStickyButtonFixedToTheFooter(entry)
  }

  if (isLinkButton || isImgButton) {
    if (getIsMobileSmall()) {
      setStickyButtonFixedToTheFooter(entry)
    } else {
      setStickyLinkPosition(stickyButton)
    }
  }
}

const debouncedHandleScrollAndResize = debounce(handleScrollAndResize, 1)

const initIntersectionObserverOnScrollAndResize = () => {
  const footerElement = document.querySelector('.cmp-footer')
  const io = new IntersectionObserver(
    (entries, _observer) => {
      ;['scroll', 'resize'].forEach(event => {
        window.addEventListener(event, () => {
          const entry = entries?.[0]

          if (entry) {
            debouncedHandleScrollAndResize(entry)
          }
        })
      })
    },
    { threshold: 0.1 },
  )

  if (footerElement) {
    io?.observe(footerElement)
  }
}

initIntersectionObserverOnScrollAndResize()
