import ModalContainer from '@react/ModalContainer'
import register from '@site/js/utils/react-register'

function ModalContainerPlugin() {
  const init = () => {
    const foundEmphLinks = document.querySelectorAll('.cmp-modal')
    foundEmphLinks.forEach(emphLink => {
      const emphLinkAsHtmlAnchorElement = emphLink as HTMLAnchorElement
      emphLink.addEventListener('click', async e => {
        e.preventDefault()

        const modalSize = emphLinkAsHtmlAnchorElement.dataset?.size || 'medium'
        const modalTitle = emphLinkAsHtmlAnchorElement.dataset?.title || ''
        const modalContainer = document.createElement('div')

        return new Promise((resolve, _reject) => {
          modalContainer.classList.add('cmp-modal-container')
          document.body.append(modalContainer)
          const openedModalContainer = document.querySelector('.cmp-modal-container')
          const queryParams = new URLSearchParams(window.location.search)
          const iframeSrc = queryParams.has('wcmmode')
            ? `${emphLinkAsHtmlAnchorElement.href}?wcmmode=${queryParams.get('wcmmode')}`
            : emphLinkAsHtmlAnchorElement.href
          register(openedModalContainer, render => render(<ModalContainer iframesrc={iframeSrc} size={modalSize} title={modalTitle} />))
          resolve()
        })
      })
    })
  }
  return {
    init,
  }
}
export { ModalContainerPlugin }
