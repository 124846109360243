/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Super class for all TsComponentRegistry components
 */
class Component<GenericHTMLElement extends HTMLElement> {
  element: GenericHTMLElement
  html: HTMLElement
  body: GenericHTMLElement

  constructor(element: GenericHTMLElement) {
    this.element = element
    this.html = <HTMLElement>document.querySelector('html')
    this.body = <GenericHTMLElement>document.documentElement
  }
}

declare global {
  interface Element {
    instance: any
  }
}

type DataLayerType = Record<string, unknown>

declare global {
  interface Window {
    ComponentsList: Array<{
      selector: string
      ComponentClass: any
    }>
    dataLayer: DataLayerType[]
    enableMSWOnDev: boolean
  }
}

function registerComponent(selector: string, ComponentClass: any) {
  if (window.ComponentsList === undefined) {
    window.ComponentsList = []
  }
  if (window.ComponentsList.filter(component => component?.selector === selector).length === 0) {
    window.ComponentsList.push({
      selector,
      ComponentClass,
    })
  }
}

function initComponents() {
  const componentsLibrary = []
  window.ComponentsList.forEach(component => {
    document.querySelectorAll(component.selector).forEach(function (element) {
      new component.ComponentClass(element, component, componentsLibrary)
    })
  })
}

export const TsComponentRegistry = {
  registerComponent,
  Component,
  initComponents,
}
